body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.footer {
    margin-top: auto;
    background-color: #111;
    color: #fff;
    padding: 10px 0 10px;
    font-family: Arial, sans-serif;
    min-height: 80px;
    transition: transform 0.3s ease-in-out;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    margin-bottom: 20px;
}

.footer-section h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #f0f0f0;
}

.footer-section h4 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #f0f0f0;
}

.footer-section p {
    font-size: 14px;
    line-height: 1.5;
    color: #bbb;
}

.footer-section a {
    color: #f0f0f0;
}

.footer-section a:hover {
    color: #19c9fe;
}

.footer-toggle {
    display: none;
}

@media (max-width: 768px) {
    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 80vh;
        transform: translateY(100%);
        z-index: 3001;
        /* z-index 증가 */
        overflow-y: auto;
    }

    .footer.open {
        transform: translateY(0);
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
    }

    .footer-section {
        text-align: center;
        margin-bottom: 30px;
    }

    .footer-toggle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 40px;
        background-color: #111;
        border-radius: 50%;
        z-index: 3002;
        /* z-index 증가 */
        cursor: pointer;
    }

    .footer-toggle span {
        display: block;
        width: 20px;
        height: 2px;
        background-color: #fff;
        margin: 2px 0;
        transition: 0.3s;
    }

    .footer-toggle.open span:nth-child(1) {
        transform: rotate(45deg) translate(4.25px, 4.25px);
    }

    .footer-toggle.open span:nth-child(2) {
        opacity: 0;
    }

    .footer-toggle.open span:nth-child(3) {
        transform: rotate(-45deg) translate(4.25px, -4.25px);
    }
}