* {
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

body {
    color: white;
    user-select: none;
    overflow: hidden;
    /* 스크롤바 숨기기 */
}

/* WebKit 기반 브라우저 (Chrome, Safari 등)에서 스크롤바 숨기기 */
html::-webkit-scrollbar,
body::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #101012;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
}

.logo img {
    height: 25px;
    padding: 1px;
    margin-top: 1px;
}

.nav-menu ul {
    display: flex;
    list-style: none;
}

.nav-menu ul li {
    margin-left: 5rem;
}

.nav-menu ul li a,
.nav-menu ul li button {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    background: none;
    border: none;
    cursor: pointer;
}

.nav-menu ul li a:hover,
.nav-menu ul li button:hover {
    text-decoration: underline;
}

.menu-icon {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.audio-player {
    position: fixed;
    top: 70px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    z-index: 999;
}

.audio-player button {
    background-color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 12px;
}

.audio-player input[type="range"] {
    width: 80px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 80%;
    height: 80%;
    max-width: 1200px;
    max-height: 800px;
    overflow: auto;
    position: relative;
    color: #333;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
}

.modal-description p {
    margin-bottom: 10px;
}

.close-button:hover {
    color: #0094FF;
}

.modal-body {
    margin-top: 2rem;
    overflow: hidden;
}

.modal-body video {
    width: 100%;
    max-height: 70vh;
    object-fit: contain;
    margin-bottom: 2rem;
    overflow: hidden;
}

.slider-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* 16:9 비율 */
    overflow: hidden;
}

.slider-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.slider-content img,
.slider-content video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.slider-button:hover {
    background: rgba(0, 0, 0, 0.7);
}

.slider-button.left {
    left: 10px;
}

.slider-button.right {
    right: 10px;
}

.modal-description {
    margin-top: 20px;
}

.modal-description h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.modal-description p {
    margin-bottom: 10px;
}

.company-intro {
    position: fixed;
    bottom: 250px;
    /* ScrollIndicator 위에 위치하도록 조정 */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 1001;
    font-family: 'Arial', sans-serif;
}

.company-intro:hover {
    background-color: rgba(0, 0, 0, 0.8);
    transform: translate(-50%, -5px);
}

.company-intro h2 {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    letter-spacing: 1px;
}

.company-intro p {
    font-size: 32px;
    opacity: 0.8;
    margin: 10;
}


.scroll-indicator {
    position: fixed;
    bottom: 160px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 16px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 20px;
}

.scroll-indicator p {
    margin: 10px 0;
}

.scroll-indicator .arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scroll-indicator .arrow span {
    display: block;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    margin: 2px;
}

.scroll-indicator .arrow.up span {
    transform: rotate(-135deg);
}

.scroll-indicator .arrow.down span {
    transform: rotate(45deg);
}

@media (max-width: 768px) {
    .nav-menu {
        position: fixed;
        right: -250px;
        /* 변경: left에서 right로 */
        top: 0;
        flex-direction: column;
        background-color: #191920;
        width: 250px;
        height: 100vh;
        text-align: left;
        transition: 0.3s;
        box-shadow: -10px 0 27px rgba(0, 0, 0, 0.05);
        /* 변경: 그림자 방향 반대로 */
        padding-top: 60px;
        z-index: 3000;
    }

    .logo img {
        height: 18px;
        padding: 1px;
        margin-top: 1px;
    }

    .nav-menu.active {
        right: 0;
    }

    .nav-menu ul {
        flex-direction: column;
        padding-left: 30px;
    }

    .nav-menu ul li {
        margin: 3rem 0;
    }

    .menu-icon {
        display: block;
        position: relative;
        z-index: 3001;
        /* 메뉴 아이콘이 항상 위에 오도록 */
    }

    .modal-content {
        width: 95%;
        height: 95%;
        padding: 20px;
    }

    .close-button {
        top: 10px;
        right: 10px;
        font-size: 1.5rem;
    }

    .slider-container {
        padding-bottom: 75%;
        /* 4:3 비율 */
    }

    .company-intro {
        padding: 1ch;
        bottom: 160px;
        width: 250px
            /* 100px 더 아래로 내림 */
    }

    .company-intro h2 {
        font-size: 20px;
    }

    .company-intro p {
        font-size: 10px;
    }

    .scroll-indicator {
        left: auto;
        right: 25px;
        transform: none;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        padding: 0;
        font-size: 10px;

    }

    .scroll-indicator .arrow {
        margin: 2% 0;
    }

}

/* 데스크톱 크기 (1024px 이상) */
@media screen and (min-width: 1024px) {
    .company-intro {
        padding: 30px;
    }

    .company-intro h2 {
        font-size: 30px;
    }

    .company-intro p {
        font-size: 22px;
    }
}

/* 대형 화면 (1440px 이상) */
@media screen and (min-width: 1440px) {
    .company-intro {
        padding: 35px;
    }

    .company-intro h2 {
        font-size: 38px;
    }

    .company-intro p {
        font-size: 25px;
    }
}