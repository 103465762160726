.audio-player {
    position: fixed;
    top: 100px;
    left: 25px;
    width: 200px;
    height: 20px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 20px;
    z-index: 1000;
}

.audio-player button {
    background: none;
    border: none;
    color: white;
    font-size: 15px;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.volume-slider {
    -webkit-appearance: none;
    width: 30px;
    height: 4px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    margin: 0 5px;
}

.volume-slider:hover {
    opacity: 1;
}

.volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: white;
    cursor: pointer;
    border-radius: 50%;
}

.volume-slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background: white;
    cursor: pointer;
    border-radius: 50%;
}

@media (max-width: 768px) {
    .audio-player {
        top: 80px;
        left: 30px;
        width: 160px;
        padding: 5px 10px;
        border-radius: 20px;
        z-index: 1000;
    }

    .audio-player button {
        font-size: 10px;
        padding: 3px;
    }
}