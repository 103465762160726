.contact-us-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3003;
}

.contact-us-popup {
    background-color: white;
    border-radius: 5px;
    width: 90%;
    height: 90%;
    max-width: 800px;
    max-height: 600px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

iframe {
    border-radius: 5px;
}